///////////////////////////////////
/// Author script : GotoAndBuzz ///
///////////////////////////////////

////////////////////////////////////////
// VARIABLES GLOBALES
var isIOS = /iP(hone|od|ad)/i.test(navigator.userAgent);

var ismobile 		= navigator.userAgent.match(/(Android)|(BlackBerry)|(HTC)|(Windows Phone)/i);
var isAPPLE 		= navigator.userAgent.match((/iPad/i) | (/iPhone/i) | (/iPod/));
var isHTML5			= navigator.userAgent.match((/iPad/i) | (/iPhone/i) | (/iPod/));
var isTACTILE 		= navigator.userAgent.match((/iPad/i) | (/iPhone/i) | (/iPod/));
var widthWindow 	= $(window).width();
var heightWindow	= $(window).height();
var stateHIRE   	= 'close';
var stateJOIN   	= 'close';
var heightSize  	= 0;

var executed = false;
var spinner;

var opts = {
  lines: 9, 			// The number of lines to draw
  length: 10, 			// The length of each line
  width: 2, 			// The line thickness
  radius: 19, 			// The radius of the inner circle
  corners: 1, 			// Corner roundness (0..1)
  rotate: 0, 			// The rotation offset
  direction: 1, 		// 1: clockwise, -1: counterclockwise
  color: '#000',		// #rgb or #rrggbb or array of colors
  speed: 2, 			// Rounds per second
  trail: 71, 			// Afterglow percentage
  shadow: false, 		// Whether to render a shadow
  hwaccel: false, 		// Whether to use hardware acceleration
  className: 'spinner', // The CSS class to assign to the spinner
  zIndex: 2e9, 			// The z-index (defaults to 2000000000)
  top: '50%', 			// Top position relative to parent
  left: '50%' 			// Left position relative to parent
};
// FIN VARIABLES GLOBALES
////////////////////////////////////////


////////////////////////////////////////
// START IOS METHODS

function callIOSMethod(method, scheme, args) {
	var url = scheme + '://infinitgame?methodname=' + method;
	if (arguments.length > 2) {
		if (args instanceof Array) {
			for (var i = 0; i < args.length; ++i) {
				url += '/arg=' + encodeURIComponent(args[i]);
			}
		} else {
			url += '/arg=' + encodeURIComponent(args);
		}
	}
	window.location = url;
}
function ShowElem(x){document.getElementById(x).style.display='block';}

function HideElem(x){document.getElementById(x).style.display='none';} 		 

function requestslide(){
	if ($('#slides').length > 0) {
		createSLIDE();
	}	
}

function toggleSeeMore() {
    if(document.getElementById("longDEShidden").style.display == 'none') {
		
        document.getElementById("longDEShidden").style.display = 'block';
		//document.getElementById("seeLess").style.display = 'inline';

        document.getElementById("longDESshowed").style.display = 'none';
        document.getElementById("seeMore").innerHTML = 'Show less';
    }
    else {
        document.getElementById("longDEShidden").style.display = 'none';
		//document.getElementById("seeLess").style.display = 'none';

        document.getElementById("longDESshowed").style.display = 'block';
		
        document.getElementById("seeMore").innerHTML = 'Show more'; 
    }
}
function initAndRestoFxPay(){
   // overwrite this function, so it will be executed only once
 //  initAndRestoFxPay = Function("");
   // real code below
   //console.log('lol?',executed)
	fxpay.configure({fakeProducts: false});
	fxpay.init({
		onerror: function(error) {
			console.log('An error occurred:', error);
		},
		oninit: function() {
			console.log("inside init"); 

			//console.log('fxpay initialized without errors');
			 fxpay.getProducts(function(err, products) {
			  if (err) {
				return console.error('Error getting products:', err);
			  }
				  
			});
		},
		onrestore: function(error, product) {
			console.log("inside on restore");
 
			if (error) {
			  console.error('Error', error, 'while restoring', 
							product.productId);
			} else {
				$.post( "/fx/identify.html" );
				
				try{
					console.log('product: ',product.productId);

				}catch(e){
					console.log('product: ','failed');	
				}
				
				try{
					console.log('productname: ',product.name);

				}catch(e){
					console.log('productname: ','failed');	
				}	
				
				try{
					console.log('productURL: ',product.productUrl);

				}catch(e){
					console.log('productURL: ','failed');	
				}
				
				try{
					console.log('product.receiptInfo: ',product.receiptInfo);

				}catch(e){
					console.log('product.receiptInfo: ','failed');	
				}	
				
				try{
					console.log('product.receiptInfo.status: ',product.receiptInfo.status);

				}catch(e){
					console.log('product.receiptInfo.status: ','failed');	
				}	
				
				try{
					console.log('product.receiptInfo.reason: ',product.receiptInfo.reason);

				}catch(e){
					console.log('product.receiptInfo.reason: ','failed');	
				}	

				try{
					console.log('product.receiptInfo.receipt: ',product.receiptInfo.receipt);

				}catch(e){
					console.log('product.receiptInfo.receipt: ','failed');	
				}	
				
				try{
					console.log('caca now');
					for (var k in product) {
						console.log('huss: ', product );
					}


				}catch(e){
					console.log('for itch','failed');	
				}					
			}
			
		}
	});
	
}
// CALLBACKS IOS

function installedGamesValueFromIOS(packagesListAPI) {
	ajaxSetPackages(packagesListAPI);
}

function userInformationsFromIOS(uInformations) {
	updateUserInformations(uInformations);
}

/*function isGameInstalledValueFromIOS(arg1) {
	// TODO
}*/

function userDidSubscriberFromIOS(statusJson) {
    $.post('/account/updateStatus.html', {status: JSON.stringify(statusJson)}, function() {
                                    document.location.reload();
                                }, 'json');
}

// END IOS METHODS
////////////////////////////////////////


////////////////////////////////////////
// START GLOBAL METHODS

function ajaxSetPackages(packagesListAPI) {

	if ("undefined" !== packagesListAPI && null !== packagesListAPI) {

		$.post(hrefsetPackages, {
			'packages': packagesListAPI
		}, function(response) {	

			if ('valid' === response.status) {
	
				eval('var packagesListAPI = ' + packagesListAPI);
				for (var key in packagesListAPI) {

					if (packagesListAPI[key] && packagesListAPI[key]!= 'false') {
						$('.gamePlay[data-package-name="' + key + '"]').html(playButton);
						$('.gamePlay[data-package-name="' + key + '"]').removeClass('installApp');
						//$('.gamePlay[data-package-name="' + key + '"]').removeClass('downloadLink');
						
						$('.gamePlay[data-package-name="' + key + '"]').addClass('playApp');
					}
				}
			}
		}, 'json');
	}
}


        
function updateUserInformations(uInformations) {
	if (null !== uInformation) {
		if (!isIOS)
			clearInterval(apiQuietLoginInterval);

		var formQL = '<form id="form_getQuietLogin" action="/account/quietLogin.html" method="post">';
		formQL += "<input type='hidden' name='redirectUrl' value='" + requestURI + "' />";
		formQL += "<input type='hidden' name='uInformation' value='" + JSON.stringify(uInformations) + "' />";
		formQL += '</form>';
		$('#bodySITE').append(formQL);
		$('#form_getQuietLogin').submit();
		
		return false;
	}
}
function validateFormMailForgot(msg1, msg2) { 

	var x = document.getElementById('forgotTextField').value;
	
	if(x.indexOf("@")== -1) {

		alert(msg1);

		return false;
	}else if(x.indexOf(".") == -1) {

		alert(msg1);

		return false;
	}else if (x.length < 5){
		alert(msg1);
		return false;		
	}else if (x == null || x == "" || x == "0") {
		alert(msg1);
		return false;
	}
	var target = $("body")[0];
	spinner = new Spinner(opts).spin(target);
}
function validateFormMail(msg1, msg2) { 
	var x = document.getElementById('enterMailTextField').value;
	
	if(x.indexOf("@")== -1) {

		alert(msg1);

		return false;
	}else if(x.indexOf(".") == -1) {

		alert(msg1);

		return false;
	}else if (x.length < 5){
		alert(msg1);
		return false;		
	}else if (x == null || x == "" || x == "0") {
		alert(msg1);
		return false;
	}
	var target = $("body")[0];
	spinner = new Spinner(opts).spin(target);
}
function validateFormLogin() { 
	
	if (typeof document.getElementById('passwordField') == 'undefined'){
		alert("Mot de passe incorrect");
		return false;	
	}else if(typeof document.getElementById('emailField') == 'undefined'){
		alert("Email incorrect");
		return false;
	}
	
	var pwd = document.getElementById('passwordField').value;
	var login = document.getElementById('emailField').value;
	
	if(login.indexOf("@")== -1) {
		alert("Email incorrect");
		return false;
	}else if(login.indexOf(".") == -1) {
		alert("Email incorrect");

		return false;
	}else if (login.length < 5){
		alert("Email incorrect");
		return false;		
	}else if (login == null || login == "" || login == "0") {
		alert("Email incorrect");
		return false;
	}else if (pwd == null || pwd == "" || pwd == "0") {
		alert("Mot de passe incorrect");
		return false;	
	}else if (pwd.length != 5){
		alert("Mot de passe incorrect");
		return false;
	}
	var target = $("body")[0];
	spinner = new Spinner(opts).spin(target);
}
// END GLOBAL METHODS
////////////////////////////////////////


////////////////////////////////////////
// HIDE NAV BAR ON iOS & ANDROID
function hideNAVBAR() {
//// If there's a hash, or addEventListener is undefined, stop here
    if (!location.hash && window.addEventListener) {

//scroll to 1
        window.scrollTo(0, 1);
        var scrollTop = 1,
                getScrollTop = function() {
            return window.pageYOffset || window.compatMode === "CSS1Compat" && window.documentElement.scrollTop || window.body.scrollTop || 0;
        },
//reset to 0 on bodyready, if needed
                bodycheck = setInterval(function() {
            if (window.body) {
                clearInterval(bodycheck);
                scrollTop = getScrollTop();
                window.scrollTo(0, scrollTop === 1 ? 0 : 1);
            }
        }, 15);

        window.addEventListener("load", function() {
            setTimeout(function() {
//at load, if user hasn't scrolled more than 20 or so...
                if (getScrollTop() < 20) {
//reset to hide addr bar at onload
                    window.scrollTo(0, scrollTop === 1 ? 0 : 1);
                }
            }, 0);
        }, false);
    }
}
 
// HIDE NAV BAR ON iOS & ANDROID
////////////////////////////////////////


////////////////////////////////////////
// TEST ORIENTATION DEVICE
function checkOrientation() {

    if (window.orientation === '0') {
        var currMode = "portrait";
    } else if (window.orientation === '-90' || window.orientation === '90' || window.orientation === '180') {
        var currMode = "landscape";
    } else {
        var currMode = "";
    }

    switch (window.orientation) {
        case 0:
            currMode = "portrait";
            break;

        case -90:
            currMode = "landscape";
            break;

        case 90:
            currMode = "landscape";
            break;

        case 180:
            currMode = "landscape";
            break;
    }
    document.getElementsByTagName("body")[0].setAttribute("class", currMode);
    return currMode;
}
// FIN TEST ORIENTATION DEVICE
////////////////////////////////////////

////////////////////////////////////////
// FONCTION REORIENT            



// FIN FONCTION REORIENT
////////////////////////////////////////

////////////////////////////////////////
// DEBUG VIEWPORT SCALE
if (navigator.userAgent.match(/iPad/i)) {
    var viewportmeta = document.querySelector('meta[name="viewport"]');
    if (viewportmeta) {
        viewportmeta.content = 'width=device-width, minimum-scale=1.0, maximum-scale=1.0';
        document.body.addEventListener('gesturestart', function() {
            viewportmeta.content = 'width=device-width, minimum-scale=0.25, maximum-scale=1.6';
        }, false);
    }
}
// FIN DEBUG VIEWPORT SCALE
////////////////////////////////////////



////////////////////////////////////////
// POPUP
function closeLAYEROVER(id) {
    $(id).fadeOut(1200, function() {
        $(this).hide();
    });
}
/*
 function positionBOX() {
 var heightBOX = $('#layerOVER .box').height();
 $('#layerOVER .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
 }
 
 function positionBoxExplanation() {
 var heightBOX = $('#layerOVERExplanation .box').height();
 $('#layerOVERExplanation .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
 }
 */
function positionBOX() {
       var heightBOX = $('#layerOVER .box').height();
       $('#layerOVER .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
       $('#layerOVER .box').show(800);
}

function positionBOXmarged() {
       var heightBOX = $('#layerOVERmarged .box').height();
       $('#layerOVERmarged .box').css('marginTop', (heightWindow  / 2) + 'px');
       $('#layerOVERmarged .box').show(800);
}
function positionBoxExplanation() {
       var heightBOX = $('#layerOVERExplanation .box').height();
       $('#layerOVERExplanation .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
       $('#layerOVERExplanation .box').show(800);
}

function positionActiveDataBoxExplanation() {
   var heightBOX = $('#datalayerOVERExplanation .box').height();
   $('#datalayerOVERExplanation .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
   $('#datalayerOVERExplanation .box').show(800);
}
function positionActiveDataBoxExplanation123() {
   var heightBOX = $('#datalayerOVERExplanation123 .box').height();
   $('#datalayerOVERExplanation123 .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
   $('#datalayerOVERExplanation123 .box').show(800);
}
function positionLayerEnterYourPhone() {
	var heightBOX = $('#layerEnterYourPhone .box').height();
	$('#layerEnterYourPhone .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerEnterYourPhone .box').show(800);
}
function positionLayerUnsubFree() {
	var heightBOX = $('#layerUnsubFree .box').height();
	$('#layerUnsubFree .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerUnsubFree .box').show(800);
}
function positionLayerEnterYourPhoneV2() {
	var heightBOX = $('#layerEnterYourPhoneV2 .box').height();
	$('#layerEnterYourPhoneV2 .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerEnterYourPhoneV2 .box').show(800);
}
function positionLayerEnterPhoneXXX() {
	var heightBOX = $('#layerEnterPhoneXXX .box').height();
	$('#layerEnterPhoneXXX .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerEnterPhoneXXX .box').show(800);
}
function positionLayerEnterPhoneCA() {
	var heightBOX = $('#layerEnterPhoneCA .box').height();
	$('#layerEnterPhoneCA .box').css('marginTop', '40px');
	$('#layerEnterPhoneCA .box').fadeIn("slow");
}
function positionLayerOperatorPicker() {
	var heightBOX = $('#layerOperatorPicker .box').height();
	$('#layerOperatorPicker .box').css('marginTop', '40px');
	$('#layerOperatorPicker .box').fadeIn("slow");
}
function positionLayerEnterPhoneMY() {
	var heightBOX = $('#layerEnterPhoneMY .box').height();
	$('#layerEnterPhoneMY .box').css('marginTop', '40px');
	$('#layerEnterPhoneMY .box').fadeIn("slow");
}
function positionLayerConfirmAT() {
	var heightBOX = $('#layerConfirmAT .box').height();
	$('#layerConfirmAT .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerConfirmAT .box').show(800);
}
function positionLayerLogin() {
	var heightBOX = $('#layerLogin .box').height();
	$('#layerLogin .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerLogin .box').show(800);
}
function positionLayerUnsubCentili() {
	var heightBOX = $('#layerUnsubCentili .box').height();
	$('#layerUnsubCentili .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerUnsubCentili .box').show(800);
}
function positionLayerForgotYourMail() {
	var heightBOX = $('#layerForgotYourMail .box').height();
	$('#layerForgotYourMail .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerForgotYourMail .box').show(800);
}
function positionLayerUnsubEchovox() {
	var heightBOX = $('#layerUnsubEchovox .box').height();
	$('#layerUnsubEchovox .box').css('marginTop', ((heightWindow - (heightBOX / 2) - 80) / 2) + 'px');
	$('#layerUnsubEchovox .box').show(800);
}
// END POPUP
////////////////////////////////////////


////////////////////////////////////////
// FONCTION RESIZE BODY
function resizeBODY() {
	try{
		var heightWindow = $(window).height();
		$('#bodySITE').css('height', heightWindow + 'px');
	}catch(e){
		console.log("Body not resized");
	}
}
resizeBODY();
// FIN RESIZE BODY
////////////////////////////////////////

////////////////////////////////////////
// SLIDER POPUP INTROP

function createSLIDE() {
    if ($('#slides').length) {

		$('#layerOVER').show();
        $('#slides').slidesjs({

            width: 600,
            height: 250,
            start: 1, // N° de la slide de départ 
            //currentClass: 'current',
			
			pagination: {
			  active: false,
			  effect: "slide"
			},
			
            callback: {
                loaded: function(number) {
                    // Use your browser console to view log
                    console.log('SlidesJS: Loaded with slide #' + number);

                    // Show start slide in log
                    $('#slidesjs-log .slidesjs-slide-number').text(number);
                },
                start: function(number) {
                    // Use your browser console to view log
                    console.log('SlidesJS: Start Animation on slide #' + number);
                },
                complete: function(number) {
                    // Use your browser console to view log
                    console.log('SlidesJS: Animation Complete. Current slide is #' + number);

                    // Change slide number on animation complete
                    $('#slidesjs-log .slidesjs-slide-number').text(number);
                }
            }
        });
    }
}
// FIN SLIDER POPUP INTROP
////////////////////////////////////////

////////////////////////////////////////
// DELETE # ANCRE
var scrolling = function() {
    var speed = 1200;
    jQuery('a[href^="#"]').bind('click', function() {
        var id = jQuery(this).attr('href');
        if (id === '#')
            goTo('body');
        else
            goTo(id);
        return(false);
        void(0);
    });
    function goTo(ancre) {
        jQuery('html,body').stop().animate({
            scrollTop: jQuery(ancre).offset().top
        }, speed, 'swing', function() {
            jQuery(ancre).attr('tabindex', '-1');
            jQuery(ancre).removeAttr('tabindex');
        });
    }
};
jQuery(function() {
	if(typeof partner !== 'undefined'){
		
		if(partner == 6){
		}else{
			scrolling();

		}
	}
});
// FIN DELETE # ANCRE
////////////////////////////////////////

function saveHeight() {
	var test = "window hieght: "+$(document).height();
	console.log(test);
	heightSize = $(document).height();
	}


////////////////////////////////////////
// POSITIONNEMENT ET PLACEMENT
function placeANDsize() {
    var widthWindow = $(window).width();
    var heightWindow = $(window).height();
    //var heightBODY = $('#bodySITE').height();
    var currMode = checkOrientation();
	

    var heightHEADER = $('#mainHEADER').height();
    var heightFOOTER = $('#mainFOOTER').height();

    var margCSS = 120; // CONSTANTE à DEFINIR (SOMME MARGIN-PADDING-BORDER)
	

    // INIT TO HIDE ADRESS BAR
    $('#bodySITE').css('min-height', (heightWindow + 60) + 'px');
	if ($('.updatePositionViaJS').length) {
	
		//var currentTest = "height in memory: "+heightSize; 
		//console.log(currentTest);	
		//window.scrollTo(0,heightSize);
	}else{
		//hideNAVBAR();
	}
    

    //if (currMode != 'landscape') {
    //    $('#wrapSITE').css('height', (heightWindow - heightFOOTER) + 'px');
    //}


    // RESIZE DIV CONTENANT LES INFOS D'UN JEU
//    if ($('.detailSECTION').length > 0) {
//        var heightNAVTAB = $('.navSECTION').height();
//
//        var currMode = checkOrientation();
//
//        if (widthWindow > 480) {
//        //    $('.detailSECTION .content').css('height', (heightWindow - heightHEADER - heightFOOTER - heightNAVTAB - margCSS) + 'px');
//        //    $('#detailGAME .rg-image').css('height', (heightWindow - heightHEADER - heightFOOTER - heightNAVTAB - margCSS - 160) + 'px');
//        } else if (widthWindow < 480 && currMode === 'landscape') {
//
//
//        } else {
//        //    $('.detailSECTION .content').css('height', (heightWindow - heightHEADER - heightFOOTER - heightNAVTAB - margCSS + 50) + 'px');
//        //    $('#detailGAME .rg-image').css('height', (heightWindow - heightHEADER - heightFOOTER - heightNAVTAB - margCSS - 60) + 'px');
//        }
//
//    }


    // REPLACE BOX 
	
    if ($('#layerOVER .box').length > 0) {
        positionBOX();
    }
	
    if ($('#layerOVERmarged .box').length > 0) {
        positionBOXmarged();
    }
}
// FIN POSITIONNEMENT ET PLACEMENT
////////////////////////////////////////

////////////////////////////////////////
// FONCTION REFRESH DATA PULL TO REFRESH
function loadNewData(){
  setTimeout(function(){
		console.log("DEBUG: inside setTimeout");
		clearTimeout();
  },1000);

	var test = parseInt(counter);
	
	
	var jsonData = JSON.parse(gamesList);
	
		for (var i = test; i < test+3; i++) {
			var cpt = jsonData.datas.games[i];

			
			
			/*
				console.log("ch0: ", element.children[0]);
				console.log("ch1: ", element.children[1]);
				console.log("ch2: ", element.children[2]);
			*/	
			
				var clone = document.getElementById("clone");
				var element = clone.cloneNode(true);
				var link1 = document.getElementById("link1");
				
				console.log("partner:" + partner);

				element.children[0].setAttribute('data-is-current-game','');


				element.children[0].setAttribute('data-game-id',cpt.id);
				element.children[0].setAttribute('data-url-scheme',cpt.url_scheme);
				element.children[0].setAttribute('data-package-name',cpt.appstore_id_android);
				element.children[0].setAttribute('data-main-activity',cpt.main_activity_android);
				if(partner == 5){
					console.log("1");
					element.children[0].setAttribute('href','/generateCodeRequestPayment.html?gameid='+ cpt.id);

				}
				if((partner == 4 || partner == 2 || partner == 7) && link1.getAttribute('href').indexOf("account") == -1){
					console.log("2");
					//element.children[0].setAttribute('href','/generateCodeRequestPayment.html?gameid='+ cpt.id);
					
					element.children[0].setAttribute('href','/games/' + cpt.id +'-detail.html?reload=true');
					//Commented because of javascript problems - for now, only put the user in the detail view
					//element.children[0].setAttribute('href','/generateCodeRequestPayment.html?gameid='+ cpt.id +);
					
					//element.children[0].setAttribute('href','https://d2bfhuxozzsc0y.cloudfront.net/APKs/BouyguesStore/' + cpt.id + '.apk');
				}if((partner == 6 || partner == 8) && link1.getAttribute('href').indexOf("account") == -1){
					console.log("partner:" + partner);

					element.children[0].setAttribute('href','/games/' + cpt.id +'-detail.html?reload=true');
				}
				
				
				var detailsHref = "/games/" + cpt.id +"-detail.html?reload=true";
				element.children[1].setAttribute('href',detailsHref);
				
				//element.children[2].innerHTML = cpt.tag;	
				var logo_url = cpt.logo;
				
				element.children[3].children[0].setAttribute('data-src',logo_url);	
				element.children[3].children[0].setAttribute('src',logo_url);
				element.children[3].children[0].setAttribute('title',cpt.game_name);			
				element.children[4].children[0].innerHTML = cpt.game_name;			
			//	element.children[4].children[1].innerHTML = cpt.category;			
				
				//element.children[4].children[1].innerHTML = cpt.shortdesc;	
				//element.children[4].children[1].children[0].setAttribute('href',detailsHref);
					
				//console.log("element :", element);

				list.innerHTML = list.innerHTML + "<li class='game border_bottom android'>" + element.innerHTML + "</li>";
				
				//console.log("i : ", i);
				//console.log("cpt.id:",cpt.id);
				//console.log("counter:",counter);
				
		}

		counter = parseInt(counter) + 3;

}		


////////////////////////////////////////
// FONCTION PENDANT LE CHARGEMENT
window.onload = function() {
	  $('.flexslider').flexslider({
		animation: "slide",
		controlNav:false,
		animationLoop:true,
		slideshow:true,
		slideshowSpeed:6000,
		animationSpeed:300,
		initDelay:0,
		randomize:true,
		directionNav:true,
	  });
	  $('.flexslider_detail').flexslider({
		animation: "slide",
		controlNav:false	,
		animationLoop:true,
		slideshow:true,
		slideshowSpeed:2000,
		animationSpeed:400,
		initDelay:0,
		randomize:true,
		directionNav:false,
	  });

	
	if(window.location.pathname.indexOf("detail") != -1){ 
		console.log("in details, try to remove");
		try{
			//var element = document.getElementById("toRemove");
			//element.parentNode.removeChild(element);
		}catch(e){
			console.log("remove failed");
		}	
			
	}
		
	$('.smoothload').loadImages({
		imgLoadedClb: function(){
		
		}, // Triggered when an image is loaded. ('this' is the loaded image)
		allLoadedClb: function(){
		}, // Triggered when all images are loaded. ('this' is the wrapper in which all images are loaded, or the image if you ran it on one image)
		imgErrorClb:  function(){}, // Triggered when the image gives an error. Useful when you want to add a placeholder instead or remove it. ('this' is the loaded image)
		noImgClb:     function(){}, // Triggered when there are no image found with data-src attributes, or when all images give an error. ('this' is the wrapper in which all images are loaded, or the image if you ran it on one image)
		dataAttr:     'src' // The data attribute that contains the source. Default 'src'.
	});
			
		try{
			spinner.stop(target);
		}catch(e){
			var target = $("body")[0];
		}
    checkOrientation();
    placeANDsize();
	

};
// FIN FONCTION PENDANT LE CHARGEMENT
//////////////////////////////////////// 
$(window).on('load', function() {
    $('.preload').attr('src', function(i,a){
        $(this).attr('src','')
            .removeClass('preload')
            .attr('src',a);
    });
});

////////////////////////////////////////
// FONCTION APRES ROTATION DEVICE
window.onorientationchange = function() {

    checkOrientation();
    placeANDsize();


	
};

// FIN FONCTION APRES ROTATION DEVICE
////////////////////////////////////////
$('a').click(function()
 {		try{
	 
			console.log("this is the test: " + window.location.href); 
			console.log("this is the test2: " + $(this).attr("href")); 
			console.log("indexOF landing  :" + $(this).attr("href").indexOf("landing"));
			console.log("indexOF #  :" + $(this).attr("href").indexOf("#"));
			console.log("indexOF facebook  :" + $(this).attr("href").indexOf("facebook"));
			console.log("indexOF twitter  :" + $(this).attr("href").indexOf("twitter"));
			console.log("indexOF plus.google  :" + $(this).attr("href").indexOf("plus.google"));
			console.log("indexOF mailto  :" + $(this).attr("href").indexOf("mailto"));
			console.log("indexOF javascript  :" + $(this).attr("href").indexOf("javascript"));

			
			if(	
				($(this).attr("href").indexOf("#") > -1) 
				|| ($(this).attr("href").indexOf("facebook") > -1) 
				|| ($(this).attr("href").indexOf("twitter") > -1) 
				|| ($(this).attr("href").indexOf("plus.google")> -1) 
				|| ($(this).attr("href").indexOf("mailto") > -1) 
				|| ($(this).attr("href").indexOf("/echovox/WIFI") > -1) 	
				|| ($(this).attr("href").indexOf("javascript") > -1) 
				
			){

			}else if ($(this).attr("src").indexOf("mtarget") > -1) {
				console.log("super_duber");
			}
			else{
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
			}
		}catch(e){
		}
	
});

$('.okButtonLP').click(function(){
	try{
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
	}catch(e){
	}
	
});
////////////////////////////////////////
// FONCTION APRES DOC READY
$(document).ready(function() {
	//if(app.session.get("platform") && app.session.get("platform") == "desktop"){
		//console.log("doc ready OK");
	//}
	console.log("is mobile ?:" + ismobile);
	if($('#layerEnterYourMail').length) {
		$('#mainHEADER').hide();
	}
	if($('#layerAnswerSMS').length) {
		$('#mainHEADER').hide();
	}
	var current_upper_url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
	console.log('current_upper_url ===' + current_upper_url);
	console.log('document.referrer ===' + document.referrer);
	console.log('document.location.href ===' + document.location.href);
	console.log('window.parent.location ===' + window.parent.location);
			
	if(current_upper_url.indexOf('mobilepay') !== -1 ){
		console.log('current_upper_url != 1');
		console.log('window.location.href' + window.location.href);
		console.log('window.top.location.href' + window.top.location.href);
		
		window.top.location.href = window.location.href ; 
	}

	$('.showInvis').on('click', function() {
		document.getElementById("inviz").style.visibility = 'visible';
	});
	
	$('#picflute').on('click', function() {
		console.log("broutille");
		/*document.getElementById("menu").style.width = "31%";
		document.getElementById("wrapSITE").style.left = "31%"; */
	});	
	
	$('.showDataActivation').on('click', function() {
		console.log('on activateDATA');

		try{
			
			spinner.stop(target);
		}catch(e){
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
			spinner.stop(target);
		}

			//$('#datalayerOVERExplanation').show();
			//positionActiveDataBoxExplanation();

			$('#datalayerOVERExplanation').on('click', function() {
			   // closeLAYEROVER('#datalayerOVERExplanation');
			});
	  
		window.scrollTo(0,0);
	});	
	$('.show2DataActivation').on('click', function() {

		try{
			
			spinner.stop(target);
		}catch(e){
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
			spinner.stop(target);
		}
		closeLAYEROVER('#layerLogin');
		//$('#datalayerOVERExplanation').show();
		//positionActiveDataBoxExplanation();

		$('#datalayerOVERExplanation').on('click', function() {
		   // closeLAYEROVER('#datalayerOVERExplanation');
		});
	  
		window.scrollTo(0,0);
	});
	
	$('.showEnterYourPhone').on('click', function()	{
			console.log('enter your phone');
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";
		//	document.getElementById("layerLANDSCAPE").className.replace( /(?:^|\s)layerLANDSCAPE_BT2(?!\S)/g , '' )

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterYourPhone').show();
			positionLayerEnterYourPhone();

			$('#layerEnterYourPhone').on('click', function() {
				document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
				
				closeLAYEROVER('#layerEnterYourPhone');
			});
		  
			window.scrollTo(0,0);
		});
	$('.showEnterYourPhoneV2').on('click', function()	{
			console.log('enter your phoneV2');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterYourPhoneV2').show();
			positionLayerEnterYourPhoneV2();

			$('#layerEnterYourPhoneV2').on('click', function() {
				
				closeLAYEROVER('#layerEnterYourPhoneV2');
			});
		  
			window.scrollTo(0,0);
		});		
	$('.showLayerEnterPhoneXXX').on('click', function()	{
			console.log('enter your layerEnterPhoneXXX');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterPhoneXXX').show();
			positionLayerEnterPhoneXXX();

			$('#layerEnterPhoneXXX').on('click', function() {
				var element_to_scroll_to = document.getElementById('layerEnterPhoneXXX');
				element_to_scroll_to.scrollIntoView();
			});
			var element_to_scroll_to = document.getElementById('layerEnterPhoneXXX');
			element_to_scroll_to.scrollIntoView();
		});
	$('.showLayerEnterPhoneCA').on('click', function()	{
			console.log('enter your layerEnterPhoneCA');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterPhoneCA').show();
			positionLayerEnterPhoneCA();

			$('#layerEnterPhoneCA').on('click', function() {
				closeLAYEROVER('#layerEnterPhoneCA');
			});
			var element_to_scroll_to = document.getElementById('layerEnterPhoneCA');
			element_to_scroll_to.scrollIntoView();
		});
		
		$('.showLayerOperatorPicker').on('click', function()	{
			console.log('enter your layerOperatorPicker');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerOperatorPicker').show();
			positionLayerOperatorPicker();

			$('#layerOperatorPicker').on('click', function() {
				closeLAYEROVER('#layerOperatorPicker');
			});
			var element_to_scroll_to = document.getElementById('layerOperatorPicker');
			element_to_scroll_to.scrollIntoView();
		});		
	$('.showUnsubPopin').on('click', function()	{
			console.log('enter your layerEnterPhoneMY');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterPhoneMY').show();
			positionLayerEnterPhoneMY();

			$('#layerEnterPhoneMY').on('click', function() {
				closeLAYEROVER('#layerEnterPhoneMY');
			});
			var element_to_scroll_to = document.getElementById('layerEnterPhoneMY');
			element_to_scroll_to.scrollIntoView();
		});				
	$('.showLayerConfirmATBilling').on('click', function()	{
			console.log('enter your showLayerConfirmATBilling');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerConfirmAT').show();
			positionLayerConfirmAT();

			$('#layerConfirmAT').on('click', function() {
				var element_to_scroll_to = document.getElementById('layerConfirmAT');
				element_to_scroll_to.scrollIntoView();
			});
			var element_to_scroll_to = document.getElementById('layerConfirmAT');
			element_to_scroll_to.scrollIntoView();
		});			
		
	$('#confirmedAge').on('click', function()	{
		closeLAYEROVER('#layerConfirmAge');
		if(document.getElementById('buttonXXXfloat') !== null){	
			document.getElementById("buttonXXXfloat").style.display = 'block';
		}
	});			
	$('#confirmedNotAge').on('click', function()	{
		window.location.href = "https://126405ba27f3.999traffic.com/?p=21313&media_type=mainstream";
	});
	
	$('.showLayerLogin').on('click', function()	{
		console.log('Login');
		document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";

		try{
			
			spinner.stop(target);
		}catch(e){
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
			spinner.stop(target);
		}

		$('#layerLogin').show();
		positionLayerLogin();

		$('#layerLogin').on('click', function() {
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
			
			closeLAYEROVER('#layerLogin');
		});
	  
		window.scrollTo(0,0);
	});

	$('.showUnsubCentili').on('click', function()	{
		console.log('showUnsubCentili');



		$('#layerUnsubCentili').show();
		positionLayerUnsubCentili();

		$('#layerUnsubCentili').on('click', function() {
			
			closeLAYEROVER('#layerUnsubCentili');
		});
	  
		window.scrollTo(0,0);
	});	
	$('#layerForgotYourMail').on('click', function() {
		
		closeLAYEROVER('#layerForgotYourMail');
	});	
	$('.forgotPassword').on('click', function()	{
		console.log('ForgotPw');
		document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";

		try{
			
			spinner.stop(target);
		}catch(e){
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
			spinner.stop(target);
		}

		$('#layerForgotYourMail').show();
		closeLAYEROVER('#layerLogin');

		positionLayerForgotYourMail();

		$('#layerForgotYourMail').on('click', function() {
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
			
			closeLAYEROVER('#layerForgotYourMail');
		});
	  
		window.scrollTo(0,0);
	});
	$('.showUnsubscribeURLS').on('click', function()	{
		document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";

		try{
			
			spinner.stop(target);
		}catch(e){
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
			spinner.stop(target);
		}

		$('#layerUnsubEchovox').show();
		closeLAYEROVER('#layerLogin');

		positionLayerUnsubEchovox();

		$('#layerUnsubEchovox').on('click', function() {
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
			
			closeLAYEROVER('#layerUnsubEchovox');
		});
	  
		window.scrollTo(0,0);
	});	
	$('.showScrollDataActivation').on('click', function() {
		console.log('showScrollDataActivation triggered');
		try{
			
			spinner.stop(target);
		}catch(e){
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
			spinner.stop(target);
		}

		//	$('#datalayerOVERExplanation').show();
		//	positionActiveDataBoxExplanation();

			$('#datalayerOVERExplanation').on('click', function() {
			   // closeLAYEROVER('#datalayerOVERExplanation');
			});
	  
      
		window.scrollTo(0,0);
		$(".menu-scroll").trigger("click");

    });		
	
	$('.showScrollEnterYourPhone').on('click', function() {
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";

		console.log('showScrollEnterYourPhone triggered');
		try{
			spinner.stop(target);
		}catch(e){
			console.log('caca: '+e);
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
			spinner.stop(target);
		}

		$('#layerEnterYourPhone').show();
		positionLayerEnterYourPhone();

		$('#layerEnterYourPhone').on('click', function() {
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
			
		   closeLAYEROVER('#layerEnterYourPhone');
		});
      
		window.scrollTo(0,0);
		$(".menu-scroll").trigger("click");

    });	
	$('.showScrollLayerLogin').on('click', function() {
		document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";

		try{
			spinner.stop(target);
		}catch(e){
			console.log('caca: '+e);
			var target = $("body")[0];
			spinner = new Spinner(opts).spin(target);
			spinner.stop(target);
		}

		$('#layerLogin').show();
		positionLayerLogin();

		$('#layerLogin').on('click', function() {
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
			
		   closeLAYEROVER('#layerLogin');
		});
      
		window.scrollTo(0,0);
		$(".menu-scroll").trigger("click");

    });				
	try{
		if(window.location.pathname.indexOf("/games/") != 0){
			$(".expandable").siblings('ul').slideToggle(0);
		}else{
			
			if(window.location.pathname.indexOf("detail") != -1){
				$(".expandable").siblings('ul').slideToggle(0);
			}
		}
	}catch(e){
	
	}
	
	try{
		$('.menu-scroll').bigSlide();

	}catch(e){
		console.log("failed initiating the slidemenu");
	}

	if (typeof partner !== 'undefined' && partner == 5 && !sessionStorage.alreadyClicked) {
		console.log('p=5 and no already clicked so we call initandRestore');
		initAndRestoFxPay();
		sessionStorage.alreadyClicked = "true";
	}
	
	if ($('.fxpay').length) {

		initAndRestoFxPay();
	}
	if ($('.updateAccountStatusJSv2').length) {
		console.log("inside closewrapperwebview");
		if ('undefined' !== typeof(WrapperAPI)) {
			console.log("calling wrapperAPI to update status");

			WrapperAPI.updateStatus($('.updateAccountStatusJSv2').attr('data-code'), $('.updateAccountStatusJSv2').attr('data-partner'), $('.updateAccountStatusJSv2').attr('data-end-date'));

			//WrapperAPI.closeWebView();
		}
	}
	
	$(document).click(function() {
		console.log('here we close the shiet');
		if ($(".layerConfirmAge")[0]){
			var element_to_scroll_to = document.getElementById('layerConfirmAge');
			if(element_to_scroll_to.style.display === 'visible'){
				element_to_scroll_to.scrollIntoView();
			}
		}
		

		closeLAYEROVER('#layerOVER');
		

	});
	$(document).on('click', 'a.expandable', function(e) {
	  e.preventDefault();
	  $(this).siblings('ul').slideToggle();
	});
	$(".contenu").click(function(event) {
		//alert('clicked inside marged');
		event.stopPropagation();
	});

	//var spinner = new Spinner().spin();
	//var target = document.getElementById('wrapSITE');

	//target.appendChild(spinner.el);
	//spinner.stop();

    ////////////////////////////////////////
    // RUN FONCTIONS
    //hideNAVBAR(window);
    //resizeBODY();
    placeANDsize();
	

	if ($('#layerOVER .box').length > 0) {
		positionBOX();
	}
	
	if ($('#layerOVERmarged .box').length > 0) {
		positionBOXmarged();
	}
	
		if ($('#slides').length > 0) {
			createSLIDE();
		}	

	
    // FIN RUN FONCTIONS
    ////////////////////////////////////////

    ////////////////////////////////////////
    // TABS BOOSTRAP
    $('.navSECTION a').click(function(e) {
        e.preventDefault();
        $(this).tab('show');
    });
    $('#navCAT.sectionCOMPTE ul a').click(function(e) {
        e.preventDefault();
        $(this).tab('show');
    });
    // TABS BOOSTRAP
    ////////////////////////////////////////




    $(window).resize(function() {
        placeANDsize();
    });


    ////////////////////////////////////////
    // NICESCROLL
    if ($('#navCAT').length > 0) {
        // NICE SCROLLBAR
		
			if (partner == 5 ){
				
			}else{
				
		
				$("#navCAT .content").niceScroll();
				var nice = $("#navCAT .content").getNiceScroll();
				$("#navCAT .content").getNiceScroll().hide();
			}
        // CALCUL LARGEUR CONTENEUR DES LISTES CATEGORIE
        var total = 0;

        $('#navCAT li.btn').each(function() {
            //var widthLI = ($(this).width()-($(this).css('padding-left')+$(this).css('padding-right')+$(this).css("margin-left")+$(this).css("margin-right")));
            var widthLI = ($(this).width() + 32);
            total += parseFloat(widthLI)
        });
        $('#navCAT ul').css('width', (total + 12) + 'px')
    }
    // FIN NICESCROLL
    ////////////////////////////////////////

    ////////////////////////////////////////
    // GESTION DES ACTIONS SUR BOUTONS 
    $('a').each(function() {
        resizeBODY();
    });

    $('.btnCLOSElayerOVER').click(function() {

			closeLAYEROVER('#layerOVER');
			closeLAYEROVER('.showDialog');

    });
	
    $('.btnCLOSElayerOVER3').click(function() {
		closeLAYEROVER('#layerUnsubFree');
    });
	
    $('.btnCLOSElayerOVER2').click(function() {
		closeLAYEROVER('#layerEnterYourPhone');
		
    });	
    // FIN GESTION DES ACTIONS SUR BOUTONS 
    ////////////////////////////////////////

	if ($('.setUpWifi').length) {
		console.log('Trying to call setupWifi throught infintApi | js.script ');
		if ('undefined' !== typeof(InfinitAPI)) {
			if(InfinitAPI.setUpWifiConnection(false)){
				console.log('Wifi is now ON');
				//document.location.reload();
			}else{
				console.log(' something happend switching to wifi ');					
			}
		}else{
			console.log('Not upon application, just get back to user');
		}		
	};
	
	


    $('.btnACTION').on('click', function() {
        var boxyPaiement = $(this).parents().filter('.boxyACTION').find('.boxyPAIEMENT');
        if (boxyPaiement.length) {
            if (boxyPaiement.hasClass('hide')) {
                boxyPaiement.removeClass('hide');
            } else {
                boxyPaiement.addClass('hide');
            }
        }
		
    });
    $('.fxpay').on('click', function() {
	
		 fxpay.getProducts(function(err, products) {
			if (err) {
				return console.error('Error getting products:', err);
			}

			console.log('product 1 will be the only product', products[0].productId);
			console.log('NAME:', products[0].name);
			fxpay.purchase(products[0].productId, function(error, product) {
				if (error) {
					return console.error("hress:", error);
				}
				document.getElementById("fxpayvalidation").click();
				console.log(product.productId, 'purchased and verified!');
				console.log("try to understand the receipt: " , product.receiptInfo.receipt);
				
			});
			  
		});
		
    });
    //Anchor to leaderboard
    var currentUrl = document.location.href;
    if (currentUrl.match(/#/g)) {
        var aSplit = currentUrl.split(/#/g);
        if ($('#navSECTIONGAME').find('a[href*="#' + aSplit[aSplit.length - 1] + '"]').length) {
            $('#navSECTIONGAME').find('li').removeClass('active');
            $('#navSECTIONGAME').find('a[href*="#' + aSplit[aSplit.length - 1] + '"]').parent().addClass('active');

            $('#detailGAME').find('section').removeClass('active');
            $('#detailGAME').find('#' + aSplit[aSplit.length - 1]).addClass('active');

        }
    }


        // Update account status after ipx or paypal payment
        if ($('.showDialog').length) {
            $('.showDialog').show();
            $('.showDialog').on('click', function() {
                $('.flashMessage').remove();
					document.location.reload();
            });
        }

        $('.unsubscribe').on('click', function() {

            $.getJSON('/ipx/unsubscription.html', function(response) {
                document.location.reload();
            });


        });
    
	$('#form_get_mobilephone').find('input[type="submit"]').click(
		function(response) {

			var form = $('#form_get_mobilephone');

			$.ajax({
				url: form.attr('action'),
				type: 'get',
				data: form.serialize(),
				dataType: 'json',
				success: function(response) {
					console.log("sukcezz?: ");

						closeLAYEROVER('#layerOVER');
						closeLAYEROVER('#layerEnterYourPhone');
				}
			});

				closeLAYEROVER('#layerOVER');
				closeLAYEROVER('#layerEnterYourPhone');
			return false;

		}
	);		
    $('.downloadLink2').on('click', function() {	
		console.log('onDownloadLink');

		$('#layerOVERExplanation').show();
		positionBoxExplanation();

		$('#layerOVERExplanation').on('click', function() {
			closeLAYEROVER('#layerOVERExplanation');
		});
        
		window.scrollTo(0,0);
    });	
	    // display download spinner & popin download information
    $('.downloadLink').on('click', function() {	
		
		console.log('onDownloadLink');
		if(partner == 6){
			$(this).html('Loading');
		}else{	
			$(this).html('...');
		}
            if(isIOS) {
                var partnerid = $(this).attr('data-partner-id');
                var gameid = $(this).attr('data-game-id');
                var exdate = new Date();
                exdate.setDate(exdate.getDate() + 365);
                document.cookie = "partner_for_game_" + gameid + "=" + partnerid + "; expires=" + exdate.toUTCString() + "; domain=.infinitgame.com; path=/";
            }
            $('#layerOVERExplanation').show();
            positionBoxExplanation();

            $('#layerOVERExplanation').on('click', function() {
                closeLAYEROVER('#layerOVERExplanation');
            });
        
		window.scrollTo(0,0);
    });



	
	var duplicateCounter = 0;
	$("div#gamelist2").scroll(function () {
		console.log("2222");
	});
	window.scrollTo(0, 500);
    window.scrollTo(0, -500);
	
	$(document).scroll(function () {
		//Creating slide after everything was rendered
		//if ($(document).scrollTop() >= $(document).height() - $(window).height() - 15000) {

			if ($('.allgames').length) {

				//console.log('counter' + counter );
				//console.log('duplicateCounter' + duplicateCounter );

				if(duplicateCounter  != counter){
				
					//grab the list
					list = document.getElementById("touchlist");
					//grab the loading div
					

					duplicateCounter = counter;
					loadNewData();

				}
			}
		
		$('.showDataActivation').on('click', function() {
			console.log('on activateDATA');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			//	$('#datalayerOVERExplanation').show();
			//	positionActiveDataBoxExplanation();

				$('#datalayerOVERExplanation').on('click', function() {
				   // closeLAYEROVER('#datalayerOVERExplanation');
				});
		  
			window.scrollTo(0,0);
		});
		
		$('.showEnterYourPhone').on('click', function() {
			console.log('enter your phone');
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterYourPhone').show();
			positionLayerEnterYourPhone();

			$('#layerEnterYourPhone').on('click', function() {
				document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
							
				closeLAYEROVER('#layerEnterYourPhone');
			});
		  
			window.scrollTo(0,0);
		});
		
		$('.showEnterYourPhoneV2').on('click', function() {
			console.log('enter your phoneV2');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterYourPhoneV2').show();
			positionLayerEnterYourPhoneV2();

			$('#layerEnterYourPhoneV2').on('click', function() {
							
				closeLAYEROVER('#layerEnterYourPhoneV2');
			});
		  
			window.scrollTo(0,0);
		});		
		$('.showLayerEnterPhoneXXX').on('click', function() {
			console.log('enter your layerEnterPhoneXXX');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterPhoneXXX').show();
			positionLayerEnterPhoneXXX();

			$('#layerEnterPhoneXXX').on('click', function() {
							
				var element_to_scroll_to = document.getElementById('layerEnterPhoneXXX');
				element_to_scroll_to.scrollIntoView();		
			});
		  
			var element_to_scroll_to = document.getElementById('layerEnterPhoneXXX');
			element_to_scroll_to.scrollIntoView();
		});
	$('.showLayerEnterPhoneCA').on('click', function()	{
			console.log('enter your layerEnterPhoneCA');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterPhoneCA').show();
			positionLayerEnterPhoneCA();

			$('#layerEnterPhoneCA').on('click', function() {
				closeLAYEROVER('#layerEnterPhoneCA');
			});
			var element_to_scroll_to = document.getElementById('layerEnterPhoneCA');
			element_to_scroll_to.scrollIntoView();
		});
		$('.showLayerOperatorPicker').on('click', function()	{
			console.log('enter your LayerOperatorPicker');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerOperatorPicker').show();
			positionLayerOperatorPicker();

			$('#layerOperatorPicker').on('click', function() {
				closeLAYEROVER('#layerOperatorPicker');
			});
			var element_to_scroll_to = document.getElementById('layerOperatorPicker');
			element_to_scroll_to.scrollIntoView();
		});		
		$('.showUnsubPopin').on('click', function()	{
			console.log('enter your layerEnterPhonMY');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerEnterPhoneMY').show();
			positionLayerEnterPhoneMY();

			$('#layerEnterPhoneMY').on('click', function() {
				closeLAYEROVER('#layerEnterPhoneMY');
			});
			var element_to_scroll_to = document.getElementById('layerEnterPhoneMY');
			element_to_scroll_to.scrollIntoView();
		});					
		$('.showLayerConfirmATBilling').on('click', function() {
			console.log('enter your showLayerConfirmATBilling');

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerConfirmAT').show();
			positionLayerEnterPhoneXXX();

			$('#layerConfirmAT').on('click', function() {
							
				var element_to_scroll_to = document.getElementById('layerConfirmAT');
				element_to_scroll_to.scrollIntoView();		
			});
		  
			var element_to_scroll_to = document.getElementById('layerConfirmAT');
			element_to_scroll_to.scrollIntoView();
		});
				
		$('.showEnterPhoneLpClass').on('click', function() {
			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}
			document.getElementById("papala").style.display = 'block';
			document.getElementById("hiddenform").style.display = 'block';
			document.getElementById("showEnterPhoneLpClass").style.display = 'none';
			document.getElementById("checkboxPhrase").style.display = 'none';
			document.getElementById("checkboxPayment").style.display = 'none';
			
			window.scrollTo(0,0);
		});	
		
		$('.showLayerLogin').on('click', function() {
			console.log('Login');
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerLogin').show();
			positionLayerLogin();

			$('#layerLogin').on('click', function() {
				document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
							
				closeLAYEROVER('#layerLogin');
			});
		  
			window.scrollTo(0,0);
		});	
		$('.showUnsubFree').on('click', function()	{
			console.log('showUnsubFree');
			$('#layerUnsubFree').show();
			positionLayerUnsubFree();
			window.scrollTo(0,0);
		});			
		$('.showUnsubCentili').on('click', function() {
			console.log('showUnsubCentili');


			$('#layerUnsubCentili').show();
			positionLayerUnsubCentili();

			$('#layerUnsubCentili').on('click', function() {
							
				closeLAYEROVER('#layerUnsubCentili');
			});
		  
			window.scrollTo(0,0);
		});			
		/*$('.forgotPassword').on('click', function() {
			console.log('forgotPassword');
			document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT2";

			try{
				
				spinner.stop(target);
			}catch(e){
				var target = $("body")[0];
				spinner = new Spinner(opts).spin(target);
				spinner.stop(target);
			}

			$('#layerForgotYourMail').show();
			closeLAYEROVER('#layerLogin');

			positionLayerForgotYourMail();

			$('#layerForgotYourMail').on('click', function() {
				document.getElementById("layerLANDSCAPE").className ="layerLANDSCAPE_BT";
							
				closeLAYEROVER('#layerForgotYourMail');
			});
		  
			window.scrollTo(0,0);
		});			*/	
		
	});
		

});
// FIN FONCTIONS APRES DOC READY
////////////////////////////////////////